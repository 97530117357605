@import url('https://fonts.googleapis.com/css2?family=DM+Mono&family=Inter:wght@500&display=swap');
html,
body,
#root {
	margin: 0;
	height: 100%;
	width: 100%;
	font-family: 'DM Mono', monospace;
	font-family: 'Inter', sans-serif;
}
.page {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.loading-page {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: white;
	text-align: center;
}


.darker-background {
	background-color: rgb(0, 0, 0, 85%);
	width: 100%;
	height: 100%;
	visibility: hidden;
	position: absolute;
	z-index: 99998;
}

.animate-settings {
	animation: appear 0.3s linear forwards;
}

.settings {
	position: relative;
	background-color: #faf8ef;
	border-radius: 10px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 99999;
	width: 400px;
	height: 250px;
	padding: 20px;
	font-size: 24px;

	flex-direction: column;
}

.theme-container {
	display: flex;
	flex-direction: column;
	gap: 5px;
	overflow-y: auto;
}

.theme {
	text-align: center;
	padding: 10px;
	border-radius: 15px;
	background-color: rgb(133, 176, 214);
}

.theme.active {
	background-color: rgb(137, 190, 137);
}

.right-container {
	margin-top: 30px;
}

.settings-icon:hover {
	cursor: pointer;

}

.game-container {
	width: 470px;
	height: 470px;
	position: relative;
	padding: 15px;
	background-color: rgb(0 0 0 / 10%);
	margin-top: 30px;
}

.above-container {
	display: flex;
	width: 500px;
	gap: 20px;
	align-self: center;
	margin-right: 42px;
}

.above-left {
	font-size: 40px;
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;
	text-align: start;
	flex-basis: 60%;

}

.above-right {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.score-container {
	background-color: rgb(0 0 0 / 10%);
	padding: 12.5px 15px;
	text-align: center;
	border-radius: 5px;
	color: rgb(63, 60, 54);
	font-weight: 700;
}

.scores {
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.restart-button {
	background-color: rgba(0, 0, 0, 0.329);
	border-radius: 5px;
	font-size: 24px;
	font-weight: bold;
	color: white;
	width: 100%;
	padding: 15px;
	border: none;
}

.restart-button:hover {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.534);
}

.start-button {
	background-color: #d9ab6a;
	border-radius: 5px;
	font-size: 24px;
	font-weight: bold;
	color: white;
	width: 250px;
	padding: 15px;
	border: none;
}

.start-button:hover {
	cursor: pointer;
	background-color: #a7732c;
}

.win-buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 20px;
	margin-top: 30px;
}

.game-over {
	opacity: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	user-select: none;
	visibility: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	z-index: 99999;
	background-color: rgb(0, 0, 0, 85%);
}

.animate {
	animation: appear 1s linear forwards;
}

@keyframes appear {
	0% {opacity: 0;}
	100% {
		opacity: 1;
		visibility: visible;
	}
}

.tile-container {
	position: absolute;
	top: 0px;
	left: 0px;
}

.grid-container {
	display: grid;
	grid-template-columns: auto auto auto auto;
	width: 470px;
	height: 470px;
	border-radius: 10px;
	
	gap: 15px;
}


.cell {
	height: 106.25px;
	width: 106.25px;
	border-radius: 5px;
	background-color: rgb(255 255 255 / 80%);
}



.tile {
	position: absolute;
	background-size: cover;
	background-position: center;
	image-rendering: auto;
	border-radius: 5px;
	height: 106.25px;
	width: 106.25px;
	transition: 100ms ease-in-out;
	transition-property: left, top, transform;
	transition-duration: 200ms, 200ms, 100ms;
}

.tile-2-theme-0 {
	background-image: url('./images/theme-0/2.png');
}
.tile-4-theme-0 {
	background-image: url('./images/theme-0/4.png');
}
.tile-8-theme-0 {
	background-image: url('./images/theme-0/8.png');
}
.tile-16-theme-0 {
	background-image: url('./images/theme-0/16.png');
}
.tile-32-theme-0 {
	background-image: url('./images/theme-0/32.png');
}
.tile-64-theme-0 {
	background-image: url('./images/theme-0/64.png');
}
.tile-128-theme-0 {
	background-image: url('./images/theme-0/128.png');
}
.tile-256-theme-0 {
	background-image: url('./images/theme-0/256.png');
}
.tile-512-theme-0 {
	background-image: url('./images/theme-0/512.png');
}
.tile-1024-theme-0 {
	background-image: url('./images/theme-0/1024.png');
}
.tile-2048-theme-0 {
	background-image: url('./images/theme-0/2048.png');
}
.tile-4096-theme-0 {
	background-image: url('./images/theme-0/4096.png');
}
.tile-8192-theme-0 {
	background-image: url('./images/theme-0/4096.png');
	box-shadow: 0 0 15px 1px #a8fa8a;
}
.tile-16384-theme-0 {
	background-image: url('./images/theme-0/4096.png');
	box-shadow: 0 0 15px 1px #5ac7e2;
}
.tile-32768-theme-0 {
	background-image: url('./images/theme-0/4096.png');
	box-shadow: 0 0 15px 1px #e25ad0;
}
.tile-65536-theme-0 {
	background-image: url('./images/theme-0/4096.png');
	box-shadow: 0 0 15px 4px #d6b034;
}

@media (max-width: 512px) {
	.game-container {
		width: 300px;
		height: 300px;
		padding: 10px;
	}

	.grid-container {
		width: 300px;
		height: 300px;
		
		gap: 10px;
	}

	.cell {
		height: 67.5px;
		width: 67.5px;
		border-radius: 5px;
		background-color: rgb(255 255 255 / 80%);
	}
	
	.tile {
		height: 67.5px;
		width: 67.5px;
	}

	.above-container {
		width: 300px;
		gap: 20px;
		margin-right: 0px;
	}
	
	.above-left {
		font-size: 30px;
	
	}
	
	.above-right {
		flex-direction: column;
		gap: 20px;
	}

	.scores {
		flex-direction: column;
		gap: 10px;
	}

	.start-button {
		font-size: 14px;
		width: 150px;
		padding: 10px;
	}

	.right-container {
		margin-top: 0px;
		margin-left: 10px;
	}

	.settings {

		width: 280px;
		height: 180px;
		font-size: 20px;

	}
}

@media (max-width: 324px) {
	.game-container {
		width: 250px;
		height: 250px;
		padding: 10px;
		margin-top: 20px;
	}

	.grid-container {
		width: 250px;
		height: 250px;
		
		gap: 10px;
	}

	.cell {
		height: 55px;
		width: 55px;
		border-radius: 5px;
		background-color: rgb(255 255 255 / 80%);
	}
	
	.tile {
		height: 55px;
		width: 55px;
	}

	.above-container {
		width: 270px;
		gap: 10px;
		margin-right: 0px;

	}
	
	.above-left {
		font-size: 22px;
	
	}
	
	.above-right {
		flex-direction: column;
		gap: 20px;
	}

	.scores {
		flex-direction: column;
		gap: 10px;
	}

	.start-button {
		font-size: 14px;
		width: 150px;
		padding: 10px;
	}

	.right-container {
		margin-top: 0px;
		margin-left: 10px;
	}

	.settings {

		width: 200px;
		height: 190px;
		font-size: 20px;

	}

	.win-buttons > .start-button {
		font-size: 13px;
		width: 100px;
	}
}